import React from "react";
import { Link } from "react-router-dom";
import styles from "./Products.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "./slider.css";
import { Pagination, Autoplay } from "swiper/modules";
import { productsData } from "../data";


const ProductCard = ({ product, isMobile }) => {
  const CardComponent = product.link ? "a" : "div";
  const cardProps = product.link ? { href: product.link } : {};
  
  return (
    <CardComponent
      {...cardProps}
      className={isMobile ? styles.Mcard : styles.card}
    >
      <div className={isMobile ? styles.McardImage : styles.cardImage}>
        <img src={product.image} alt={product.title} />
      </div>
      <div
        className={
          isMobile ? styles.McardTextContainer : styles.cardTextContainer
        }
      >
        <div className={isMobile ? styles.McardTitle : styles.cardTitle}>
          {product.title} {product.isComingSoon && <span>(soon)</span>}
        </div>
        <div className={isMobile ? styles.McardDesc : styles.cardDesc}>
          {product.description}
        </div>
      </div>
    </CardComponent>
  );
};

export default function Products({ id }) {
  return (
    <div id={id} className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.mainHeading}>Products</div>

          <div className={styles.subHeading}>
            Trade real-time market analytics and insights, Predict future trends, build apps with Ÿ Oracle.
          </div>
        </div>

        <div className={styles.cardContainer}>
          {productsData.map((product, index) => (
            <ProductCard key={index} product={product} isMobile={false} />
          ))}

          <div className={styles.sliderCont}>
            <Swiper
              slidesPerView={1}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Autoplay]}
            >
              {productsData.map((product, index) => (
                <SwiperSlide key={index}>
                  <ProductCard product={product} isMobile={true} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
